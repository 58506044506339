import React, {Fragment, useEffect, useRef, useState} from "react";
import * as XLSX from 'xlsx';
import Breadcrumb from "../../../layout/breadcrumb";
import {getDefaultSeller} from "../../../utils/common";
import {
    ButtonDropdown,
    Card,
    CardBody, CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,Popover,
    PopoverBody,
    PopoverHeader,
} from "reactstrap";
import {orderFilters} from "../../manage-product/product/edit/content/settings/map";
import {mapOrderResponseToTable, mapSearchAutoSuggestion} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';
import {
    closeActionDialog,
    sekoExport,
    vogaClosetExport,
    importEvriData,
    downloadBulkOrderPdf, exportEvriData, exportNextDayEvriData,
    exportOrderShippingDetails,
    exportVatDetails, generateAllPickingTicket,
    generateOrderPdf,
    generateOrderPdfData, getCurrentDeliveryPartner,
    getOrders,
    getOrderSettings,
    exportVogaClosetImport,
    importDpdData, openActionDialog, openPickingTicketDialogs,
    selectOrder, setCurrentDeliveryPartner, setCurrentDeliveryPartners, setOrderFilter,
    setSettings, updateDeliveryPartner, reassignShipping, reassignSeller,
    vogaClosetNewExport,vogaClosetDetailExport} from "../store/orderSlice";
import {Link, useHistory} from "react-router-dom";
import Select from "react-select";
import orderReducer from "../store";
import {Badge} from "react-bootstrap";
import PreLoader from "../../../share-components/ui/preLoader";
import {toast} from "react-toastify";
import moment from "moment";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import OrderFilter from "./filter";
import {getFilterValues, moreOrderFilterFields} from "../../manage-product/product/edit/content/settings/map";
import OrderActionForm from "./orderActionForm";
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import {setProductFilter} from "../../manage-product/store/prodcutSlice";
import AsyncSelect from "react-select/async";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import OrderPickingTicket from "./orderPickingTicket";
import {downloadBlobAsXLSX} from "../../../utils/common"
export const schema = yup.object().shape({});



const { afterToday } = DateRangePicker;
const defaultValues = {
    file: '',
    deliveryPartner: ''
};


const Order = (props) => {
    const OrderFormat = row => <a>{row.orderName}</a>;
    const orderState = useSelector(({order}) => order.order);
    const dispatch = useDispatch();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [btnDisable, setBtnDisable] = useState(false);
    const [checkBoxData, setCheckBox] = useState({packingSlip: [], pickingTicket: []}, {idArray: []});
    const [orderData, setOrderData] = useState([]);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [status, setStatus] = useState(null);
    const [financialStatus, setFinancialStatus] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [remoteIsland, setRemoteIsland] = useState(orderState.filter.remote_island);
    const [unallocatedShipping, setUnallocatedShipping] = useState(orderState.filter.unallocated_shipping);
    const [saveBtnLoad, setSaveBtnLoad] = useState(false)

    const orderReduxData = useSelector(selectOrder);
    const submitTypes = ["submit", "update", "save"];
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || orderReduxData.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || orderReduxData.length < 1));
    const [country, setCountry] = useState(null);
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);
    const [actionBtnDropdownOpen, setActionBtnDropdownOpen] = useState(false);
    const actionToggleBtn = () => setActionBtnDropdownOpen(prevState => !prevState);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [selectedFile, setSelectedFile] = useState(null);
    const [importDdpFile, setImportDdpFile] = useState(false);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({idArray: []});
    const [removed, setRemoved] = useState(false);
    const [pageSize, setPageSize] = useState();
    const [filterStatus, setFilterStatus] = useState('1');
    const [filterValues, setFilterValues] = useState([]);
    const [isResetFilters, setIsResetFilters] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [shippingType, setShippingType] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [deliveryPartner, setDeliverPartner] = useState(null);
    const [isChecked, setIsChecked] = useState(null);
    const [itemIdArray, setItemIdArray] = useState([]);
    const [uploadFile, setUploadFile] = useState(null);
    const authData =  useSelector(({authReducer}) => authReducer);
    const userState =  authData.data;
    const [seller, setSeller] = useState(authData.defaultSeller);
    const [timer, setTimer] = useState(null);
    const [name, setName] = useState(null);
    const [partner, setPartner] = useState(null);
    
useEffect(() => {

        if (differentPage) {
            dispatch(setOrderFilter(orderFilters(authData.defaultSeller)));
        }
    }, []);

useEffect(()=>{
    if (!differentPage && (isFilterable ||  ((isFilterable)&&authData.defaultSeller?.value != orderState.filter.seller?.value))) {
        dispatch(setOrderFilter({
            ...orderState.filter,
            seller: authData.defaultSeller,
            currentPage: 1
        }));
    }
},[authData.defaultSeller])

    useEffect(() => {
        setOrderData(mapOrderResponseToTable(orderReduxData, checkBoxData));
    }, [orderReduxData]);
    const dataMenu = [
        {
            type: 0,
            text: 'Orders',
        }
    ];
    const conditionalRowStyles = [

        {
            when: row => row.isPackingSlip,
            style: row => ({
                backgroundColor: '#FFD0D0',
            }),
        },
        {
            when: row => row.isPackingTicket,
            style: row => ({
                backgroundColor: '#AAB7F9',
            }),
        },
        {
            when: row => (row.isPackingTicket && row.isPackingSlip),
            style: row => ({
                backgroundColor: '#BEF781'
            }),


        },

    ];

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    const fulfillmentOptions = [
        {value: 'unfulfilled ', label: 'Unfulfilled'},
        {value: 'fulfilled', label: 'Fulfilled'},
        {value: 'partial', label: 'Partial'},
    ]

    const paymentStatusOptions = [
        {value: 'pending', label: 'Pending'},
        {value: 'paid', label: 'Paid'},
        {value: 'voided', label: 'Voided'},
        {value: 'partially_refunded', label: 'Partially refunded'},
        {value: 'refunded', label: 'Refunded'},
        {value: 'expired', label: 'Expired'},
    ]

    const paymentTypeOptions = [
        {id: 'shopify_payments', name: 'Shopify Payments'},
        {id: 'paypal', name: 'Paypal'},
        {id: 'Bank Deposit', name: 'Bank Deposit'},
        {id: 'others', name: 'Others'},
        // {value: 'Hokodo Buy now, Pay Later ', label: 'Hokodo Buy now, Pay Later'},
        // {value: 'manual', label: 'Manual'},

    ];

    const orderTypeOptions = [
        {id: 'normal', name: 'Normal Order'},
        {id: 'exchange', name: 'Exchange Order'},
    ]

    const checkBoxChange = (row, obj, isChecked) => {
        let checkArray = checkBoxData[obj];
        if (isChecked) {
            checkArray.push(row.id);
        } else {
            if (checkArray.includes(row.id)) {
                let packIndex = checkArray.indexOf(row.id);
                checkArray.splice(packIndex, 1);
            }
        }

        // console.log(pack);
        setCheckBox(prevState => ({
            ...prevState,
            obj: checkArray
        }));

    }
    const allCheckBoxChange = (obj, isChecked) => {
        const data = orderData;
        let checkArray = checkBoxData[obj];
        if (isChecked) {
            data.map((item, index) => {
                if (!checkArray.includes(item.id)) {
                    item.customer && checkArray.push(item.id);
                }
            });
        } else {
            data.map((item, index) => {
                if (checkArray.includes(item.id)) {
                    let packIndex = checkArray.indexOf(item.id);
                    checkArray.splice(packIndex, 1);
                }
            });

        }

        // console.log(pack);
        setCheckBox(prevState => ({
            ...prevState,
            obj: checkArray
        }));


    }
    useEffect(() => {

        setOrderData(mapOrderResponseToTable(orderReduxData, checkBoxData));
        dispatch(getOrderSettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [checkBoxData, dispatch]);

    const submitBtnSaveRef = useRef();

    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const PopoverBasicItem = (props) => {
        const {id,btntext,Popoverbody} = props;
        const [popover, setPopover] = useState(false)
        const Toggle = () => setPopover(!popover);
        return (
            <>
                <p style={{cursor:"pointer"}} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
                <Popover
                    placement="top"
                    isOpen={popover}
                    target={"Popover-" + id}
                    toggle={Toggle}
                    trigger="hover"
                >
                    <PopoverBody>
                        {Popoverbody}
                    </PopoverBody>
                </Popover>
            </>
        );
    };

    let tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.orderName,
            sortable: true,
            cell: (row) =><Link className="text-primary" style={{cursor: "pointer"}} to={`${process.env.PUBLIC_URL}/order/${row.id}/view`}>{row.orderName}</Link>,
            center: false,
        },

        
    ];
    
    if (userState.user.role.name!=="Seller") {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Seller',
                // selector: row => row.seller,
                selector: (row) => {
                    const sellerListArray = row.sellerList ? row.sellerList.split(', ') : [];
                  
                    if (sellerListArray.length > 1) {
                      const sellerNames = sellerListArray.join(', ');
                  
                      return (
                        <PopoverBasicItem id={row.id} btntext={row.seller} Popoverbody={sellerNames} />
                      );
                    } else {
                      return row.seller;
                    }
                  },
                sortable: true,
                center: false,
            }
        ];
    }

    tableColumns = [
        ...tableColumns,
        {
            name: 'Date',
            selector: row => row.orderDate,
            sortable: true,
            center: false,
            width: '200px',
        },
        {
            name: 'Payment Status',
            selector: row => <p className="text-capitalize">{row.paymentStatus}</p>,
            sortable: true,
            center: false,
        },
        {
            name: 'Total (£)',
            selector: row => row.total.toFixed(2),
            sortable: true,
            right: true,
        },
        {
            name: 'Payment Method',
            selector: row => row.payment,
            sortable: true,
            center: false,
        },
    ];

    if (userState.user.role.name!=="Seller") {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Fulfillment  Status',
                selector: row => row.fulfillmentStatus,
                sortable: true,
                center: false,
            },
        ];
    }

    if (userState.user.role.name !== "Seller" && orderState.filter.seller && orderState.filter.seller.value === "20" ? orderState.filter.seller.value : ''){
        tableColumns = [                         
            ...tableColumns,
            {
                name: 'Dispatch Date',
                selector: row => row.dispatch_date,
                sortable: true,
                center: false,
            }
        ];
    }

    tableColumns = [
        ...tableColumns,
        {
            name: 'Order Type',
            selector: row => row.orderType,
            sortable: true,
            center: false,
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            center: false,
        },
    ];

    if (userState.user.role.name==="Seller") {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Dispatch Status',
                selector: row => row.dispatch_status,
                sortable: true,
                center: false,
            }
        ];
    }

    if (userState.user.role.name==="Seller") {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Dispatch Date',
                selector: row => row.dispatch_date
                ,
                sortable: true,
                center: false,
            }
        ];
    }

    if ((userState.user.role.name==="Seller") && (currentUser.permission).includes('Create Packing Slips')) {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Packing Slip',
                selector: row => row.payment,
                sortable: false,
                center: true,
                cell: (row) => <input disabled={!row.customer} onChange={(e) => {

                    checkBoxChange(row, 'packingSlip', e.target.checked);


                }} checked={row.isPackingSlip} type={"checkbox"}/>
            }
        ];
    }

    if ((userState.user.role.name==="Seller") && (currentUser.permission).includes('Create Picking Ticket')) {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Picking Ticket',
                selector: row => row.id,
                sortable: false,
                center: true,
                cell: (row) => <input disabled={!row.customer} onChange={(e) => {
                    checkBoxChange(row, 'pickingTicket', e.target.checked);

                }} checked={row.isPackingTicket} type={"checkbox"}/>
            }

        ];
    }

    tableColumns = [
        ...tableColumns,
        {
            name: 'Action',
            selector: (row) => <><a onClick={() => onTrackingView(row)}
                                    className={`btn btn-sm p-2 ${!row.customer || row.paymentStatus==='Refunded' || row.is_order_returned === 1 ? 'disabled' : ''}`}
                                    style={{backgroundColor: `${row.btnColor}`, color: 'white'}}
                                    title={row.fulfillmentStatus.toUpperCase()}> <i className="fa fa-truck"/>
            </a>
                {!row.isFullFilled && <a onClick={() => onReturn(row)}
                                         className={`ml-1 btn btn-sm btn-warning p-2 ${!row.customer || row.paymentStatus==='Refunded' || row.is_order_returned === 1 ? 'disabled' : ''}`}>
                    <i className="fa fa-mail-reply"/></a>}

                {/* {(!row.isFullFilled && (row.fulfillmentStatus === 'Fulfilled' || row.fulfillmentStatus === 'Partial')) ? (
                <a onClick={() => onReturn(row)} className={`ml-1 btn btn-sm btn-warning p-2 ${!row.customer || row.paymentStatus === 'Refunded' || row.is_order_returned === 1 ? 'disabled' : ''}`}>
                    <i className="fa fa-mail-reply" />
                </a>
                ) : (
                <a className="ml-1 btn btn-sm btn-warning p-2 disabled">
                    <i className="fa fa-mail-reply" />
                </a>
                )} */}
                    </>,
            sortable: true,
            center: false,
        }
    ];

    useEffect(() => {

        if (isFilterable) {
            const filter = orderState.filter;
            if (differentPage && JSON.stringify(filter) != JSON.stringify(orderFilters(authData.defaultSeller))) {
                setDifferentPage(false);
            } else {
                dispatch(getOrders({
                    page:filter.currentPage,
                    // page_size: filter.pageSize,
                    pageSize: filter.pageSize,
                    status: orderState.filter.status? orderState.filter.status.value:'',
                    financialStatus: orderState.filter. financialStatus?orderState.filter. financialStatus.value:'',
                    paymentType:orderState.filter.payment_type? orderState.filter.payment_type.id:'',
                    country: orderState.filter.country?orderState.filter.country.name:'',
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                    shippingType:orderState.filter.shipping_type?orderState.filter.shipping_type.id:'',
                    deliveryPartner: orderState.filter.deliveryPartners?orderState.filter.deliveryPartners.id:'',
                    seller: orderState.filter.seller? orderState.filter.seller.value:'',
                    island:orderState.filter.remote_island?1:0,
                    unallocatedShipping:orderState.filter.unallocated_shipping?1:0,
                    unallocatedSeller:orderState.filter.unallocated_seller?1:0,
                    orderType:orderState.filter.order_type?orderState.filter.order_type.id:'',
                    name:orderState.filter.name,
                    partner:orderState.filter.partner?orderState.filter.partner.id:''
                })).then(res=> {
                    isSetLoading(false);
                })
                setDifferentPage(false);
            }
        }
        setIsFilterable(true);

    }, [orderState.filter.currentPage, orderState.filter.status, orderState.filter. financialStatus, orderState.filter.payment_type, orderState.filter.country, startDate, endDate,isResetFilters, orderState.filter.shipping_type,
        orderState.filter.deliveryPartners, orderState.filter.seller,orderState.filter.pageSize,orderState.filter.remote_island,orderState.filter.order_type,orderState.filter.name, orderState.filter.unallocated_shipping, orderState.filter.unallocated_seller, orderState.filter.partner]);

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });


    const onEdit = (row) => {

    }


    const exportShipping = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            deliveryPartner : orderState.filter.deliveryPartners?.id
        }
        const deliveryParnterName=orderState.filter.deliveryPartners?orderState.filter.deliveryPartners.name+"_":"";
        const outputFilename = `${deliveryParnterName}orders_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportOrderShippingDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const exportVat = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        const outputFilename = `vat_data_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportVatDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const generateOrderPdf = () => {
        setBtnDisable(true)
        // isSetLoading(true)
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            orderIdArray:itemIdArray
        }
        dispatch(generateOrderPdfData(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `order${(new Date().toJSON().slice(0, 10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            // isSetLoading(false)
            setBtnDisable(false)
        });

    };

    const generateEvriTracking = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            service: 'Standard'
        }
        const outputFilename = `evri_data_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportEvriData({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const generateEvriNextDayTracking = () => {
        setBtnDisable(true)
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            service: 'Next Day'
        }
        const outputFilename = `evri_next_day_data_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportNextDayEvriData({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }
    
    const sekoExportClick = () => {
        setBtnDisable(true)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        const outputFilename = `DPD_SEKO_${(moment().format('DDMMYYYY'))}.csv`;
        dispatch(sekoExport({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const vogaClosetExportClick = () => {
        setBtnDisable(true)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        const outputFilename = `voga_closet_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(vogaClosetExport({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }
    const exportVogaClostToDpd=()=>{
        setBtnDisable(true)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        const outputFilename = `voga_closet_dpd${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportVogaClosetImport({...data})).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }
    const vogaClosetExportNewClick=()=>{
        setBtnDisable(true)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        dispatch(vogaClosetNewExport({...data})).then(res => {
            const downloadLink = document.createElement('a');
            downloadLink.href = res.payload;
            // link.setAttribute('download', '');
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Clean up by removing the download link from the document body
            document.body.removeChild(downloadLink);

            setBtnDisable(false);
        });
    }
    const vogaClosetExportDetailClick=()=>{
        setBtnDisable(true)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        dispatch(vogaClosetDetailExport({...data})).then(res => {
            const downloadLink = document.createElement('a');
            downloadLink.href = res.payload;
            // link.setAttribute('download', '');
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Clean up by removing the download link from the document body
            document.body.removeChild(downloadLink);
            setBtnDisable(false);
        });
    }

    const generatePackingSlip = () => {
        processPDFDownload(checkBoxData.packingSlip, "Package Slip");
    }
    const generatePickingTicket = () => {
        processPDFDownload(checkBoxData.pickingTicket, "Picking Ticket");
    }

    const onView = (state) => {
        history.push('/order/' + state.target.id + '/view');
    }
    const onReturn = (row) => {
        if (row.customer) {
            history.push('/order/' + row.id + '/return-items');
        } else {
            toast.error("No customer found for the selected order", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    const onTrackingView = (row) => {
        if (row.customer) {
            history.push('/order/' + row.id + '/tracking/add');
        } else {
            toast.error("No customer found for the selected order", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    }

    const handleFileInputChange = (event) => {
        setUploadFile(event)
        setSelectedFile(event[0]);
    };

    const onSubmitForm = (event) => {
        setSaveBtnLoad(true);
        if (event.deliveryPartner) {
            const uniqueArr = itemIdArray.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });

            const data = {
                delivery_partner_id: event.deliveryPartner?event.deliveryPartner.id : '',
                order_id: uniqueArr
            }
            dispatch(updateDeliveryPartner({...data})).then(res=>{
                if (!res.errors) {
                    setSaveBtnLoad(false);
                    dispatch(closeActionDialog())
                }
            })

        } else {

            setSaveBtnLoad(true);
            const formData = new FormData();
            formData.append('csv_file', selectedFile);
            if(orderState.actionDialog.modalType == 2) {
                dispatch(importDpdData(formData)).then(res => {
                    if (!res.error) {
                        setSaveBtnLoad(false);
                        dispatch(closeActionDialog());
                    }
                    setSaveBtnLoad(false);
                });
            }else{
                dispatch(importEvriData(formData)).then(res => {
                    if (!res.error) {
                        setSaveBtnLoad(false);
                        dispatch(closeActionDialog());
                    }
                    setSaveBtnLoad(false);
                });
            }

        }
    }

    const processPDFDownload = (ids, name) => {
        const data = {
            ids: ids,
            name: name
        }
        dispatch(downloadBulkOrderPdf(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `bulk_${name.replace(/\s+/g, '-')}_${(new Date().toJSON().slice(0, 10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const pageChange = (e) => { 

        // setPage(e)
        dispatch(setOrderFilter({...orderState.filter, currentPage:e}))
    }

    const onPageSizeChange = (e) => {
        // setPageSize(e)
        dispatch(setOrderFilter({...orderState.filter, currentPage: 1, pageSize:e}))
    }
    const toggleRowSelected = (id) => {
        // console.log(id)
    }

    let idArray = [];
    const handleChange = (state) => {
        // idArr = [...itemIdArray]
        if (state.selectedCount>0) {
            setIsChecked(true);
            state.selectedRows.map((item, i) => {
                idArray.push(item.id);
                setItemIdArray(idArray);
                setOrderCheckBoxData(prevState => ({
                    idArray:[...prevState.idArray, ...idArray],

                }));
            });

            // const selectedIds = state.selectedRows.map((item) => item.id);
            // setItemIdArray((prevIds) => {
            //   const updatedIds = [...prevIds, ...selectedIds];
            //   return updatedIds.filter((id) => state.selectedRows.some((item) => item.id === id));
            // });
          
            // const dat = {
            //   order_id: [...itemIdArray, ...selectedIds],
            // };
            
            // if (state.selectedRows.length===1) {

                const dat  = {
                    order_id: idArray
                }
                dispatch(getCurrentDeliveryPartner(dat)).then(res=>{
                    dispatch(setCurrentDeliveryPartner(res.payload.current_delivery_partner))
                    dispatch(setCurrentDeliveryPartners(res.payload.delivery_partners))
                })
            // } else {
            //     dispatch(setCurrentDeliveryPartner(null))
            // }

            
        }  else {
                setIsChecked(false);
                setItemIdArray([]);
                dispatch(setCurrentDeliveryPartner(null));
              }
    }


    const clearFilter = () => {
        
        let orderField = {...orderState.filter};
        moreOrderFilterFields().map(function (item){
            orderField[item.key] = "";
        });
        dispatch(setOrderFilter(orderField));
    };

    const filterState = orderState.filter;

    const clearSelectAll=()=>{

    }

    const closeDialog = () => {}
    const setTagData = (obj) => {
        dispatch(setOrderFilter(obj));
        // if (obj.country==='') {
        //     setCountry(null)
        // }
        // if (obj.payment_type==='') {
        //     setPaymentType(null);
        // }
        // if (obj.shipping_type==='') {
        //     setShippingType(null);
        // }
        // if (obj.order_type==='') {
        //     setOrderType(null);
        // }
        // if (obj.remote_island===false) {
        //     setRemoteIsland(false);
        // }
        // if (obj.unallocated_shipping===false) {
        //     setUnallocatedShipping(false);
        // }
        // if (obj.partner==='') {
        //     setPartner(null);
        // }
    }


    // const defaultSellers = { label: "Goddiva - Goddiva Ltd", value: "20" };
    // const [defaultSeller, setDefaultSeller] = useState(20);
    // useEffect(() => {
    //   setSeller(defaultSeller);
    // }, []);

    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
                    <Breadcrumb title="Orders" data={dataMenu}/>
                    <Container fluid={true}>
                        {((userState.user.role.name==="Seller") && (currentUser.permission).includes('Create Packing Slips') && (currentUser.permission).includes('Create Picking Ticket')) &&

                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <div className="p-4">
                                                    <div style={{display: "flex"}}>
                                                        {((currentUser.permission).includes('Create Packing Slips') && (currentUser.permission).includes('Create Picking Ticket')) &&
                                                            <div className=" mr-1">
                                                                <Badge className="mr-2 badge-green" pill>{' '}</Badge>
                                                                <span>Packing & Picking ({(checkBoxData.packingSlip.filter(element => checkBoxData.pickingTicket.includes(element))).length})</span>
                                                            </div>}
                                                        {(currentUser.permission).includes('Create Packing Slips') &&
                                                            <div className=" mr-1">
                                                                <Badge style={{backgroundColor: "#efed88 !important"}}
                                                                       className="mr-2 badge-red" pill>{' '}</Badge>
                                                                <span>Packing Slip ({checkBoxData.packingSlip.length})</span>
                                                            </div>}
                                                        {(currentUser.permission).includes('Create Picking Ticket') &&
                                                            <div className=" mr-1">
                                                                <Badge className="mr-2 badge-blue" pill>{' '}</Badge>
                                                                <span>Picking Ticket ({checkBoxData.pickingTicket.length})</span>
                                                            </div>}
                                                        <div className="float-right">
                                                            <Badge className="mr-2 badge-blue" pill>{' '}</Badge>
                                                            <span>Picking Ticket ({checkBoxData.pickingTicket.length})</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </Col>

                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    {(checkBoxData.packingSlip.length > 0 && (currentUser.permission).includes('Create Packing Slips')) &&
                                                        <a onClick={generatePackingSlip}
                                                           className="btn btn-sm btn-primary mr-2"> <i
                                                            className="fa fa-plus"></i> Packing Slip</a>}
                                                    {(checkBoxData.pickingTicket.length > 0 && (currentUser.permission).includes('Create Picking Ticket')) &&
                                                        <a onClick={generatePickingTicket}
                                                           className="btn btn-sm btn-success"> <i
                                                            className="fa fa-plus"></i> Picking Ticket</a>}
                                                </div>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>}
                        <Row>
                            <Col sm="12">

                                    <Card>
                                        <Row>
                                            <Col md={3}>
                                                <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                                    <DatePicker 
                                                        dateRange={dateRange} 
                                                        dateRangeChange={(date)=>{
                                                            setDateRange(date)
                                                        }}
                                                        defaultValue={[TodayDate, sevenDays]}
                                                        disabledDate={afterToday()}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3}></Col>
                                            <Col md={6}>

                                                {((userState.user.role.name!=="Seller") || (userState.user.is_primary_seller)) &&
                                                    <>
                                                        <div className='float-sm-right pt-3 pb-3 pr-3'>
                                                            <ButtonDropdown isOpen={actionDropdownOpen}
                                                                            toggle={actionToggle}>
                                                                <DropdownToggle caret color="primary" className="btn btn-primary btn-square" disabled={btnDisable}>
                                                                    {btnDisable ? ( // Show the spinner if loading state is true
                                                                        <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                                                    ) : (
                                                                        'Export' // Show the regular text if loading state is false
                                                                    )}
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => {
                                                                        exportShipping()

                                                                    }
                                                                    }>Shipping Export CSV</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        exportVat()

                                                                    }
                                                                    }>IOSS VAT CSV</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        generateOrderPdf()

                                                                    }
                                                                    }>Invoice PDF</DropdownItem>

                                                                    <DropdownItem onClick={() => {
                                                                        generateEvriNextDayTracking()

                                                                    }
                                                                    }>EVRI Next Day</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        generateEvriTracking();

                                                                    }
                                                                    }>EVRI Standard</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        sekoExportClick();

                                                                    }
                                                                    }>SEKO Export</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        vogaClosetExportClick();

                                                                    }
                                                                    }>Voga Closet Export</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        exportVogaClostToDpd();

                                                                    }
                                                                    }>Voga Closet DPD</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        vogaClosetExportNewClick();

                                                                    }
                                                                    }>Voga Closet - New file</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        vogaClosetExportDetailClick();

                                                                    }
                                                                    }>Voga Closet - Detailed file</DropdownItem>
                                                                </DropdownMenu>
                                                            </ButtonDropdown>
                                                        </div>


                                                        <div className='float-sm-right pt-3 pb-3 pr-3'>
                                                            <ButtonDropdown isOpen={actionBtnDropdownOpen}
                                                                            toggle={actionToggleBtn}>
                                                                <DropdownToggle caret color="success"
                                                                                className="btn btn-success btn-square">
                                                                    Action
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => {
                                                                        dispatch(openPickingTicketDialogs(true))


                                                                    }
                                                                    }>Generate all picking tickets</DropdownItem>
                                                                    {isChecked && <DropdownItem onClick={() => {
                                                                        const dat= {
                                                                            type: 1
                                                                        }
                                                                        dispatch(openActionDialog({dat}));

                                                                    }
                                                                    }>Reassign delivery partner</DropdownItem>}
                                                                    
                                                                    <DropdownItem onClick={() => {
                                                                        setUploadFile("");
                                                                        setSelectedFile("");
                                                                        const dat= {
                                                                            type: 2
                                                                        }
                                                                        dispatch(openActionDialog({dat}));

                                                                    }
                                                                    }>Import DPD orders</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        setUploadFile("");
                                                                        setSelectedFile("");
                                                                        const dat= {
                                                                            type: 3
                                                                        }
                                                                        dispatch(openActionDialog({dat}));

                                                                    }
                                                                    }>Evri Import</DropdownItem>
                                                            
                                                            {itemIdArray.length > 0 && orderState.filter.unallocated_shipping && (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                    const uniqueReassign = itemIdArray.filter(
                                                                        (value, index, self) => self.indexOf(value) === index
                                                                    );
                                                                    const data = {
                                                                        order_id: uniqueReassign
                                                                    };
                                                                    dispatch(reassignShipping({ ...data }));
                                                                    dispatch(getOrderSettings());
                                                                    }}
                                                                >
                                                                    Reassign Shipping
                                                                </DropdownItem>
                                                                )}

                                                                {itemIdArray.length > 0 && orderState.filter.unallocated_seller && (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                    const uniqueReassignSeller = itemIdArray.filter(
                                                                        (value, index, self) => self.indexOf(value) === index
                                                                    );
                                                                    const data = {
                                                                        order_id: uniqueReassignSeller
                                                                    };
                                                                    dispatch(reassignSeller({ ...data }));
                                                                    dispatch(getOrderSettings());
                                                                    }}
                                                                >
                                                                    Reassign Seller
                                                                </DropdownItem>
                                                                )}
                                                                    
                                                                </DropdownMenu>
                                                            </ButtonDropdown>
                                                        </div>
                                                    </>}


                                            </Col>
                                        </Row>
                                    </Card>

                            </Col>
                        </Row>
                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                               {userState.user.role.name!=="Seller" &&
                                    <Col md={userState.user.role.name!=="Seller" ? 2:2} >
                                        <Nav tabs style={{border: "none", alignItems: "center", alignContent: "center"}}>
                                            <NavItem style={{border: "none", alignItems: "center", alignContent: "center"}}><NavLink style={{border: "none", alignItems: "center", alignContent: "center"}} className={filterStatus === '1' ? "active" : ''}
                                                              onClick={() => {
                                                                  setFilterStatus('1')
                                                              }}>Fulfillment</NavLink></NavItem>
                                            <NavItem style={{border: "none", alignItems: "center", alignContent: "center"}}><NavLink style={{border: "none", alignItems: "center", alignContent: "center"}} className={filterStatus === '2' ? "active" : ''}
                                                              onClick={() => {
                                                                  setFilterStatus('2')
                                                              }}>Payment</NavLink></NavItem>
                                        </Nav>
                                    </Col>
                                    }
                                    {userState.user.role.name!=="Seller" ? (
                                        <Col md={2}>
                                            {filterStatus !== '2' && <FormGroup>
                                                <Select
                                                    placeholder={<div>Status</div>}
                                                    className="select2-filter"
                                                    isClearable
                                                    cacheOptions
                                                    styles={customStyles}
                                                    options={fulfillmentOptions}
                                                    defaultValue={orderState.filter.status}
                                                    onChange={(e) => {
                                                        // console.log(e)
                                                        // setStatus(e ? e.value : null)
                                                        dispatch(setOrderFilter({
                                                            ...orderState.filter,
                                                            status:e ? e : null,
                                                            currentPage: 1,
                                                        }));
                                                    }}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}

                                                />
                                            </FormGroup>}
                                            {filterStatus !== '1' &&  <FormGroup>
                                                <Select

                                                    placeholder={<div>Status</div>}
                                                    className="select2-filter"
                                                    isClearable
                                                    cacheOptions
                                                    styles={customStyles}
                                                    options={paymentStatusOptions}
                                                    defaultValue={orderState.filter. financialStatus}
                                                    onChange={(e) => {
                                                        setFinancialStatus(e ? e.value : null)
                                                        dispatch(setOrderFilter({
                                                            ...orderState.filter,
                                                             financialStatus:e ? e : null,
                                                            currentPage: 1,
                                                        }));
                                                    }}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}

                                                />
                                            </FormGroup>}
                                        </Col>
                                    ) : 

                                    (
                                        <Col md={2}>
                                            <FormGroup>
                                                <Select
                                                    placeholder={<div>Status</div>}
                                                    className="select2-filter"
                                                    isClearable
                                                    cacheOptions
                                                    styles={customStyles}
                                                    options={paymentStatusOptions}
                                                    defaultValue={orderState.filter. financialStatus}
                                                    onChange={(e) => {
                                                        setFinancialStatus(e ? e.value : null)
                                                        dispatch(setOrderFilter({
                                                            ...orderState.filter,
                                                             financialStatus:e ? e : null,
                                                            currentPage: 1,
                                                        }));
                                                    }}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>)
                                    }
                                {((userState.user.role.name!=="Seller") || (currentUser.is_primary_seller)) &&  (
                                    <Col md={userState.user.role.name!=="Seller" ? 2 :2}>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder={<div>Delivery Partners </div>}
                                            className="select2-filter"
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            defaultValue={orderState.filter.deliveryPartners}
                                            options={orderState.settings.deliveryPartners}
                                            onChange={(e) => {
                                                // setDeliverPartner(e ? e.id : null);
                                                // console.log([e])
                                                dispatch(setCurrentDeliveryPartner([{...e}]))
                                                dispatch(setOrderFilter({
                                                    ...orderState.filter,
                                                    deliveryPartners:e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}

                                        />

                                    </Col>)
                                }

                                    {userState.user.role.name!=="Seller" ?<Col md={2}>
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            defaultOptions
                                            placeholder="select Seller"
                                            // defaultValue={orderState.filter.seller}
                                            loadOptions={sellerPromiseOption}
                                            styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {
                                        
                                            }}
                                            // value={seller}
                                            value={orderState.filter.seller}
                                            onChange={(e) => {
                                                // setSeller(e)
                                                dispatch(setOrderFilter({
                                                    ...orderState.filter,
                                                    seller:e ? e : null,
                                                    currentPage: 1,
                                                }));
                                               
                                                clearSelectAll();
                                                // dispatch(setProductFilter({
                                                //     // ...productState.filter,
                                                //     seller: e ? e : null,
                                                //     currentPage: 0
                                                // }));
                                                // clearSelectAll();
                                                // setSearchSeller(e ? e.value : null);
                                            }}

                                            

                                        />
                                    </Col>:''}
                                    <Col sm={2}>
                                        <Form  onSubmit={(e)=>{
                                            e.preventDefault();
                                        }}>
                                            <FormGroup className="m-0">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={orderState.filter.name}
                                                    onChange={(e)=>{
                                                        clearTimeout(timer);
                                                       
                                                        const newTimer = setTimeout(()=>{
                                                            // setName(e.target.value);
                                                            dispatch(setOrderFilter({
                                                                ...orderState.filter,
                                                                name:e.target.value,
                                                                currentPage: 1,
                                                            }));

                                                        },500);
                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Search Order"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>

                                {((userState.user.role.name!=="Seller") || (currentUser.is_primary_seller)) &&  (
                                    <Col style={{ position:'absolute', right: '15px'}} md={userState.user.role.name!=="Seller" ? 2 :2}>
                                        <div className={`product-sidebar`}>
                                            <div className="filter-section">
                                                <Card>
                                                    <CardHeader>
                                                        <h6 className="mb-0 f-w-600" onClick={() => {
                                                            document.querySelector(".customizer-contain").classList.add('open');
                                                        }
                                                        }>Filters
                                                            <span className="pull-right">
                                                                <i className="fa fa-chevron-right toggle-data"></i>
                                                              </span>
                                                        </h6>
                                                    </CardHeader>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>) 
                                }

                                </Row>
                            </div>
                        </div>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            {moreOrderFilterFields().map((item, i) =>
                                                filterState[item.key] && <Badge key={i}
                                                                                color="white">{item.name}{getFilterValues(item.type, filterState[item.key])}
                                                <a className="btn-xs btn-default" onClick={() => {
                                                    let obj = {...orderState.filter};

                                                    if ([item.key][0] === "remote_island") {
                                                        obj[item.key] = false;
                                                        setRemoteIsland(false)
                                                    } else {
                                                        obj[item.key] = "";
                                                    }
                                                    setTagData(obj);

                                                }}><i style={{color: 'white'}} className="fa fa-close "></i></a></Badge>
                                            )}
                                        </div>
                                        {(userState.user.role.name==="Seller") && orderData.length > 0 && <Row className="mb-2">
                                            <Col md="12">
                                                <div className='float-sm-right' style={{display: 'flex'}}>
                                                    {(currentUser.permission).includes('Create Packing Slips') &&
                                                        <div className="checkbox checkbox-dark mr-5">
                                                            <Input id="packing_slip_check_box" type="checkbox"
                                                                   onChange={(e) => {
                                                                       allCheckBoxChange('packingSlip', e.target.checked);

                                                                   }}/>
                                                            <Label for="packing_slip_check_box">Packing Slip</Label>
                                                        </div>}

                                                    {(currentUser.permission).includes('Create Picking Ticket') &&
                                                        <div className="checkbox checkbox-dark">
                                                            <Input id="picking_ticket_check_box" type="checkbox"
                                                                   onChange={(e) => {
                                                                       allCheckBoxChange('pickingTicket', e.target.checked);
                                                                   }}/>
                                                            <Label for="picking_ticket_check_box">Picking Ticket</Label>
                                                        </div>}
                                                </div>
                                            </Col>


                                        </Row>}
                                        <Row>
                                            <div className="table-responsive product-table">
                                                <BackInStockTable
                                                    paginationTotalRows={orderState.orderTableRow}
                                                    idArray={orderCheckBoxData}
                                                    remove={removed}
                                                    tableColumns={tableColumns}
                                                    backStockData={orderData}
                                                    backInRequestState={orderState}
                                                    onView={onView}
                                                    onEdit={onEdit}
                                                    pageChange={pageChange}
                                                    onPageSizeChange={onPageSizeChange}
                                                    toggleRowSelected={toggleRowSelected}
                                                    handleChange={handleChange}
                                                    paginationRowsPerPageOptions={[10,25,50,100]}
                                                    paginationDefaultPage={orderState.filter.currentPage}
                                                    paginationPerPage={orderState.filter.pageSize}
                                                    />
                                            </div>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <div className="customizer-contain">
                                <div className="customizer-header">
                                    <i className="icon-close" onClick={() => {
                                        document.querySelector(".customizer-contain").classList.remove('open');
                                    }
                                    }></i>
                                    <h5 className="filter-header">{"Filters      "} <a className="a-text-color ml-2"> <i className="fa fa-refresh"
                                                                                               title="clear filters"
                                                                                               onClick={() => {
                                                                                                   clearFilter();
                                                                                                   clearSelectAll();
                                                                                                   setIsResetFilters(true);

                                                                                               }
                                                                                               }></i></a></h5>
                                    <OrderFilter customStyles={customStyles}   clearSelectAll={clearSelectAll}
                                                 orderState={orderState} paymentTypeOptions={paymentTypeOptions} setPaymentType={setPaymentType}
                                                 setCountry={setCountry} setFilterValues={setFilterValues} setShippingType={setShippingType} setRemoteIsland={setRemoteIsland}
                                                 remoteIsland={remoteIsland} orderTypeOptions={orderTypeOptions} setOrderType={setOrderType} unallocatedShipping={unallocatedShipping} setUnallocatedShipping={setUnallocatedShipping}
                                                 setPartner={setPartner}
                                    />
                                </div>
                            </div>
                        </Row>
                    </Container>
                    <OrderPickingTicket   orderState={orderState}>

                    </OrderPickingTicket>

                    <OrderActionForm
                        reset={reset}
                        formState={formState}
                        handleSubmit={handleSubmit}
                        orderState={orderState}
                        title='Product Commodity'
                        selector='product'
                        dialog={({orderState}) => orderState.orderState.actionDialog}
                        closeDialog={closeDialog}
                        setValue={setValue}
                        getValues={getValues}
                        buttonDisable={buttonDisable}
                        submitBtnSaveRef={submitBtnSaveRef}
                        handleFileInputChange={handleFileInputChange}
                        onSubmitForm={onSubmitForm}
                        customStyles={customStyles}
                        trigger={trigger}
                        uploadFile={uploadFile}
                        saveBtnLoad={saveBtnLoad}

                    />
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(Order);