import { combineReducers } from '@reduxjs/toolkit';
import order from "./orderSlice";
import draftOrder from "./draftOrderSlice";
import checkout from "./checkoutSlice";
import groupReturn from './groupReturnSlice';
import pendingOrderList from './pendingOrderSlice';
import cancelOrder from './cancelOrderSlice';
import pickingTicket from './pickingTicketSlice';

const orderReducer = combineReducers({
    order,
    draftOrder,
    checkout,
    groupReturn,
    pendingOrderList,
    cancelOrder,
    pickingTicket
});

export default orderReducer;