import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapReturnRequestResponseToTable, mapSearchAutoSuggestion} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import {getAllPendingReturnRequest, setReturnRequestData, exportPendingReturnDetails} from "../store/orderSlice";
import {
    getAllPendingReturnRequest,
    getOrderSettings, setOrderData,
    setReturnRequestData,
    setSettings,
    showPendingReturnRequestList, exportPendingReturnDetails, selectOrder, setPendingReturnFilter
} from "../store/orderSlice";
import {useHistory, useParams, Link} from "react-router-dom";
import orderReducer from "../store";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PreLoader from "../../../share-components/ui/preLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";


import Select from "react-select";
import {getCustomerList, setCustomers} from "../../backInStock/store/backStockSlice";
import AsyncSelect from "react-select/async";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import {getBrands} from "../../manage-product/store/inventorySlice";
import { DateRangePicker } from 'rsuite';
import {pendingReturnFilters} from "../../manage-product/product/edit/content/settings/map";

const { afterToday } = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const PendingReturn = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [page, setPage] = useState(1);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    // const [loading, isSetLoading] = useState(true);
    const [search,setSearch]=useState(null);
    const [customer,setCustomer]=useState(null);
    const [seller,setSeller]=useState(null);
    const orderState = useSelector(({ order }) => order.order);
    const backInRequestState = useSelector(({backStore}) => backStore.backStoreRequest);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [searchSeller, setSearchSeller] = useState('');
    const [brands, setBrands] = useState(null);
    const [brandsVal, setBrandsVal] = useState(null);
    const [reason, setReturnReason] = useState(null);
    const [timer, setTimer] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [type, setReturnType] = useState(null);
    const orderPendingReturn = useSelector(selectOrder);
    const submitTypes = ["submit", "update", "save"];
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || orderPendingReturn.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || orderPendingReturn.length < 1));
    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Pending Return Requests"
        }
    ];

    const authData =  useSelector(({authReducer}) => authReducer);
    useEffect(() => {
        if (differentPage) {
            dispatch(setPendingReturnFilter(pendingReturnFilters(authData.defaultSeller)));
        }
    }, []);
    useEffect(()=>{
        if (!differentPage && (isFilterable ||  ((isFilterable)&&authData.defaultSeller?.value != orderState.pendingFilters.seller?.value))) {
            dispatch(setPendingReturnFilter({
                ...orderState.pendingFilters,
                seller: authData.defaultSeller,
                currentPage: 1
            }));
        }
    },[authData.defaultSeller])


    useEffect(() => {
        if(dateRange[0]!==null && dateRange[1]!==null)
            getData();
    }, [dateRange]);

    useEffect(() => {
        const data = {
            value: '',
            seller_id: orderState.pendingFilters.seller? orderState.pendingFilters.seller.value:'',
        }
        dispatch(getBrands(data)).then(res=>{
            setBrands(res.payload);
        });
    },[dispatch, orderState.pendingFilters.seller])

    useEffect(()=>{
        dispatch(getCustomerList()).then(res => {
            dispatch(setCustomers(res.payload))
        });
    },[dispatch]);

   
    const getData=()=>{
        if (isFilterable) {
            const pendingFilters = orderState.pendingFilters;
            if (differentPage && JSON.stringify(pendingFilters) != JSON.stringify(pendingReturnFilters(authData.defaultSeller))) {
                setDifferentPage(false);
            } else {
                dispatch(getAllPendingReturnRequest({
                    page:pendingFilters.currentPage,
                    pageSize: pendingFilters.pageSize,
                    dateRangeX:dateRange[0]!==null?moment(dateRange[0]).format('YYYY-MM-DD'):null,
                    dateRangeY:dateRange[1]!==null?moment(dateRange[1]).format('YYYY-MM-DD'):null,
                    // start_date: moment(startDate).format('YYYY-MM-DD'),
                    // end_date: moment(endDate).format('YYYY-MM-DD'),
                    seller: orderState.pendingFilters.seller? orderState.pendingFilters.seller.value:'',
                    search: orderState.pendingFilters.search,
                    brand: orderState.pendingFilters.brand? orderState.pendingFilters.brand.id:'',
                    // reason: orderState.pendingFilters.reason? orderState.pendingFilters.reason.value:'',
                    type: orderState.pendingFilters.type? orderState.pendingFilters.type.value:'',
                })).then(res=> {
                    dispatch(setReturnRequestData(res.payload));
                    isSetLoading(false);
                })
                setDifferentPage(false);
            }
        }
        setIsFilterable(true);
    }
    useEffect(() => {
        getData();
    }, [orderState.pendingFilters.currentPage, orderState.pendingFilters.pageSize, orderState.pendingFilters.seller,
        orderState.pendingFilters.search, orderState.pendingFilters.brand, orderState.pendingFilters.type,]);

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    const tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.orderName,
            cell: (row) =><Link className="text-primary" style={{cursor: "pointer"}} target="_blank" to={`${process.env.PUBLIC_URL}/order/${row.order_id}/view`}>{row.orderName}</Link>,
            sortable: true,
            center: false,
        },
        {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
            center: false,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row => row.id,
            cell:(row)=>row.status_id===1?<a className="text-primary" style={{cursor:"pointer"}} onClick={()=>{onEdit(row)}} id={row.id}><i className="fa fa-reply"></i></a>:"",
            sortable: false,
            center: false,
        }
    ];

    const pageChange = (e) => { 
        dispatch(setPendingReturnFilter({...orderState.pendingFilters, currentPage:e}))
    }
    const onPageSizeChange = (e) => {
        dispatch(setPendingReturnFilter({...orderState.pendingFilters, currentPage: 1, pageSize:e}))
    }
    const onEdit = (row) => {
        dispatch(getOrderSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(res.payload));
            }
        });
        const data = {
            params: {seller:authData.defaultSeller?.value}
        }
        dispatch(showPendingReturnRequestList(row.id, data))
            .then(res => {
                if (!res.error) {
                    console.log(res.payload);
                    return dispatch(setOrderData(res.payload));
                }
            })
            .then(() => {
                history.push(`/order/pending-return-requests/${row.id}`);
            })
            .catch(error => {
                // Handle any errors that occurred during the dispatch
                console.error(error);
            });

    }

    const [importDdpFile, setImportDdpFile] = useState(false);

    const exportPendingReturn = () => {
        isSetLoading(true)
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            seller:searchSeller?.value,
            brandsVal: brandsVal ? brandsVal : '',
        }
        const outputFilename = `Pending_Return_Report_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportPendingReturnDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            isSetLoading(false)
        });
    }

    const returnTypeOptions = [
        {value: 'return', label: 'Return'},
        {value: 'exchange', label: 'Exchange'},
       
    ]

    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Pending Return Requests" data={dataMenu}/>
            <Container fluid={true}>
                <div className="product-grid">
                    <div className="feature-products">
                    <Row>
                        <Col sm="12">
                            <div className='float-sm-right' style={{marginBottom: "1.5rem", marginTop: "1.5rem"}}>
                                <Button color="primary" className="btn btn-primary btn-square"
                                onClick={() => { exportPendingReturn() }}
                                >Export</Button>
                            </div>
                        </Col>
                    </Row>
                        <Row>
                        
                            <Col md={userState.user.role.name!=="Seller" ? '' :4}>
                                <Form>
                                    <FormGroup className="row">
                                        <Col sm="12">
                                        <DatePicker 
                                            dateRange={dateRange} 
                                            dateRangeChange={(update)=>{
                                                setDateRange(update)
                                            }}
                                            disabledDate={afterToday()}
                                            defaultValue={[TodayDate, sevenDays]}
                                        />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            {userState.user.role.name!=="Seller" &&<Col md={userState.user.role.name!=="Seller" ? '' :4}>
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    menuPortalTarget={document.body}
                                    value={orderState.pendingFilters.seller}
                                    defaultOptions
                                    placeholder="select Seller"
                                    loadOptions={sellerPromiseOption}
                                    styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    onChange={(e) => {
                                        dispatch(setPendingReturnFilter({
                                            ...orderState.pendingFilters,
                                            seller:e ? e : null,
                                            currentPage: 1,
                                        }));
                                    }}

                                />
                            </Col>}
                            <Col  md={userState.user.role.name!=="Seller" ? '' :4}>
                                <Select
                                    placeholder={<div>Seller Brand</div>}
                                    className="select2-filter"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={brands}
                                    defaultValue={orderState.pendingFilters.brand}
                                    onChange={(e) => {
                                        dispatch(setPendingReturnFilter({
                                            ...orderState.pendingFilters,
                                            brand:e ? e : null,
                                            currentPage: 1,
                                        }));
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                            </Col>
                            {/*<Col  md={userState.user.role.name!=="Seller" ? 3 :3}>*/}
                            {/*    <Select*/}
                            {/*        placeholder={<div>Return Reason</div>}*/}
                            {/*        className="select2-filter"*/}
                            {/*        getOptionLabel={(option) => option.name}*/}
                            {/*        getOptionValue={(option) => option.id}*/}
                            {/*        isClearable*/}
                            {/*        cacheOptions*/}
                            {/*        styles={customStyles}*/}
                            {/*        options={orderState.customerReturnReasons}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setReturnReason(e ? e.id : null)*/}
                            {/*        }}*/}
                            {/*        components={{*/}
                            {/*            IndicatorSeparator: () => null*/}
                            {/*        }}*/}

                            {/*    />*/}
                            {/*</Col>*/}
                            
                            <Col md={3}>
                                <FormGroup>
                                    <Select
                                        placeholder={<div>Return Type</div>}
                                        className="select2-filter"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={returnTypeOptions}
                                        defaultValue={orderState.pendingFilters.type}
                                        // defaultValue={type}
                                        onChange={(e) => {
                                            dispatch(setPendingReturnFilter({
                                                ...orderState.pendingFilters,
                                                type:e ? e : null,
                                                currentPage: 1,
                                            }));
                                            // dispatch(setOrderFilter({
                                            //     ...orderState.filter,
                                            //         financialStatus:e ? e : null,
                                            //     currentPage: 1,
                                            // }));
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={userState.user.role.name!=="Seller" ? '' :4}>
                                <Form  onSubmit={(e)=>{
                                    e.preventDefault();
                                }}>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={orderState.pendingFilters.search}
                                            onChange={(e)=>{
                                                clearTimeout(timer);
                                                const newTimer = setTimeout(()=>{
                                                    dispatch(setPendingReturnFilter({
                                                        ...orderState.pendingFilters,
                                                        search:e.target.value,
                                                        currentPage: 1,
                                                    }));

                                                },500);
                                                setTimer(newTimer)


                                            }}
                                            placeholder="Search Order"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>

                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                    <div className="table-responsive product-table">
                        <CommonDataTable
                            headerColumns={tableColumns}
                            gridData={orderState.returnRequestData?mapReturnRequestResponseToTable(orderState.returnRequestData):""}
                            paginationServer
                            noAction
                            paginationTotalRows={orderState.orderTableRow}
                            paginationRowsPerPageOptions={[10,25,50,100]}
                            onChangeRowsPerPage={onPageSizeChange}
                            onChangePage={pageChange}
                        />

                    </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(PendingReturn);