import React from 'react'
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

export default function DatePicker(props) {

  const { defaultValue, disabledDate, placement }=props;
  const styles = { minHeight: '50px !important', border: 'none !important', display: 'block', paddingLeft:85};

  const {dateRange,dateRangeChange}=props;
  const [startDate, endDate] = dateRange;
  console.log(dateRange);

  return (
    <div className="dashboard-datepicker">
        <DateRangePicker 
            size="lg"
            style={styles}
            appearance="subtle"
            placeholder="Select Date Range"
            className="form-control hide-border-control"
            character=" - "
            format='dd/MM/yyyy'
            placement={placement}
            defaultValue={[new Date(startDate),new Date(endDate)]}
            disabledDate={disabledDate}
            startdate={startDate}
            enddate={endDate}
            onChange={(update) => {
              dateRangeChange(update);
          }}
            cleanable={false}
        />
    </div>
  )
}

